/*--------------------------------------------------------------
# Default Configuration
--------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

body {
  background-color: #fff;
}

a {
  color: #000 !important;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Loading
--------------------------------------------------------------*/

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fd6c19;
}

.loader img {
  width: 100px;
  height: 100px;
}

.loader-no-full {
  display: flex;
  width: 50px;
  height: 50px;
  margin: 40px auto 100px auto;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

.navigation {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  color: #fff;
}

.nav-links {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-links a {
  color: #fff;
}
/* LOGO */
.logo {
  padding: 20px;
  font-size: 32px;
}

.navigation-logo-img {
  max-height: 50px;
}

.navigation-logo-img-mb {
  visibility: hidden;
  width: 0;
}
/* NAVBAR MENU */
.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 1em;
  margin-right: 1em;
  font-size: 16px;
}
.menu li:hover {
  transition: 0.3s ease;
}
.menu li {
  padding: 5px 14px;
  list-style: none;
  cursor: pointer;
}

.navigation-button {
  padding: 10px 30px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  text-transform: uppercase;
  font-size: 16px;
  min-width: max-content;
  font-weight: bold;
  cursor: pointer;
  margin-right: 30px;
}

.navigation-button a {
  color: white !important;
}

.navigation-button:hover {
  border: 1px solid #000;
}

.navigation-button:hover a {
  color: #000 !important;
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/

/* CHECKBOX HACK */
input[type="checkbox"] {
  display: none;
}
/*HAMBURGER MENU*/
.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
  padding: 20px;
}
/* APPLYING MEDIA QUERIES */
@media (max-width: 1080px) {
  .menu {
    display: none;
    position: absolute;
    background-color: #fd6c19;
    border-radius: 10px;
    top: 100px;
    right: 20px;
    width: 80%;
    padding: 10px;
    text-align: right;
  }

  .menu li:hover {
    display: inline-block;
    background-color: #18191c;
    transition: 0.3s ease;
  }
  .menu li + li {
    margin-top: 12px;
  }
  input[type="checkbox"]:checked ~ .menu {
    display: block;
  }
  .hamburger {
    display: block;
    color: #fd6c19;
  }

  .nav-links {
    flex-direction: row-reverse;
  }

  .navigation-logo-img {
    visibility: hidden;
    width: 0;
    margin-left: 0;
  }

  .navigation-logo-img-mb {
    visibility: visible;
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .dropdown {
    top: 50px;
    left: 0;
    z-index: 5000;
  }
  .dropdown li:hover {
    background-color: #19181c;
  }

  .navigation-button {
    border: 1px solid #fd6c19;
  }

  .navigation-button a {
    color: #fd6c19 !important;
  }
}

/*--------------------------------------------------------------
# HOME
--------------------------------------------------------------*/
header {
  background: radial-gradient(
        90.49% 104.24% at 100.19% 1.81%,
        #fd6c19 0%,
        rgba(255, 93, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #fff;
  text-align: center;
}

.header {
  min-height: 40vh;
  padding: 50px 0;
}

.headerScreen {
  text-align: left;
  padding: 30px;
}

.headTitle {
  padding-top: 20px;
  font-size: 48px;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}

.headTitle span {
  font-size: 64px;
}

.headSubTitle {
  font-size: 16px;
  color: #000;
}

.headButton {
  display: inline-flex;
  margin-top: 30px;
}

.spacer {
  margin-bottom: 70px;
}

.navigation-button-testimonial {
  padding: 10px 30px;
  border-radius: 10px;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

.navigation-button-start {
  padding: 10px 30px;
  background-color: transparent;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 16px;
  border: 1px solid #fd6c19;
  min-width: max-content;
  font-weight: bold;
  cursor: pointer;
  margin-right: 30px;
  color: #fd6c19 !important;
}

.navigation-button-start:hover {
  border: 1px solid #f38342;
  color: #f38342 !important;
}

@media (max-width: 1080px) {
  header {
    background: #fff;
  }
  .header {
    padding: 30px;
  }

  .headTitle {
    font-size: 36px;
  }

  .headTitle span {
    font-size: 48px;
  }

  .headerScreen {
    text-align: center;
  }

  .headSubTitle {
    font-size: 12px;
  }

  .headButton {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

/*--------------------------------------------------------------
# SECTION GLOBAL
--------------------------------------------------------------*/

.homeSection {
  padding: 30px;
}

.homeSectionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.homeSectionTitle h2 {
  font-size: 2.8rem;
  text-transform: capitalize;
}

.homeSectionTitle p {
  color: #595959;
  max-width: 700px;
}

@media (max-width: 1020px) {
  .homeSection {
    padding: 20px;
  }

  .homeSectionTitle h2 {
    font-size: 2rem;
  }
}

/*--------------------------------------------------------------
# PICTURES SECTION
--------------------------------------------------------------*/

.picSection {
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url("../img/services/developpement.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
  width: 100%;
  height: 780px;
}

.projectOne {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 30px;
  border-bottom: 30px solid #FFF;
  border-left: 30px solid #FFF;
}

.projectTwo {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 30px;
  border-top: 30px solid #FFF;
  border-right: 30px solid #FFF;
}

.buttonProjects {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #FFF;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.buttonTechno {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #FFF;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.buttonProjects p {
  color: white;
  margin-right: 50px;
  font-weight: bold;
  font-size: 20px;
}

.buttonTechno p {
  color: white;
  margin-right: 50px;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 1020px) {
  .picSection {
    height: 300px;
    margin: 20px 0;
  }

  .projectOne {
    display: none;
  }
  
  .projectTwo {
    display: none;
  }

  .buttonProjects p {
    font-size: 14px;
  }
  
  .buttonTechno p {
    font-size: 14px;
  }
}


/*--------------------------------------------------------------
# SERVICES
--------------------------------------------------------------*/

.servicesRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0;
}

.servicesCard {
  display: flex;
  justify-content: center;
  background-color: #FD6C19;
  align-items: center;
  border-radius: 10px;
  width: 30%;
  padding: 20px;
  color: white;
  text-align: center;
  height: 400px;
}

.servicesRow .servicesCard:first-child {
  background-color: #FF602C;
}

.servicesRow .servicesCard:last-child {
  background-color: #EC834C;
}

.servicesCard img {
  background-color: white;
  max-width: 100px;
  padding: 30px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.servicesCard h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.servicesCard p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

@media (max-width: 1020px) {
  .servicesRow {
    flex-wrap: wrap;
  }

  .servicesCard {
    width: 100%;
    margin: 20px 0;
  }
}

/*--------------------------------------------------------------
# METHODOLOGIE
--------------------------------------------------------------*/

.methoCard {
  background-color: #ffe3d2;
  margin: 30px 0;
  color: #000;
  border-radius: 30px;
  position: relative;
  transition: 0.2s;
}

.methoCard:hover {
  background-color: #fd6c19;
  color: #fff;
  transition: 0.2s;
}

.methoCardRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.methoCardRow .methoCardContent {
  display: inline-flex;
  justify-content: flex-start;
  padding: 50px;
  max-width: 70%;
}

.methoCardContent span {
  font-size: 24px;
  margin-right: 20px;
  margin-top: 5px;
}

.methoCardContent h2 {
  font-weight: bold;
  font-size: 32px;
}

.methoCardRow img {
  position: absolute;
  bottom: 0;
  right: 50px;
  max-width: 150px;
  max-height: 150px;
  padding: 0 5%;
}

@media (max-width: 1020px) {
  .methoCardRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .methoCardRow .methoCardContent {
    padding: 20px;
    max-width: 100%;
  }

  .methoCardContent span {
    display: none;
  }

  .methoCardContent h2 {
    text-align: center;
    font-size: 24px;
  }

  .methoCardContent p {
    text-align: center;
    margin: 10px 0;
  }

  .methoCardRow img {
    position: relative;
    bottom: 0;
    right: 0;
    max-width: 150px;
    max-height: 150px;
    padding: 0 0;
  }
}

/*--------------------------------------------------------------
# CALL TO ACTION
--------------------------------------------------------------*/

.callToAction {
  padding: 50px 0;
  margin: 50px 0;
  background-color: #fd6c19;
  position: relative;
}

.callToActionRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
}

.callToActionRow img {
  position: absolute;
  right: 10%;
  bottom: 0;
}

.callToActionContent {
  padding: 0 50px;
}

.callToActionTitle {
  font-size: 48px;
  font-weight: bold;
}

.callToActionRow input {
  width: 400px;
  padding: 15px;
  margin-top: 30px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 18px;
}

.callToActionRow button {
  margin-left: 20px;
  padding: 18px 20px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  color: white;
  outline: none;
}

@media (max-width: 1020px) {
  .callToActionRow {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .callToActionRow img {
    position: relative;
    right: inherit;
  }

  .callToActionRow input {
    width: 70%;
    padding: 15px;
    font-size: 14px;
  }

  .callToActionContent {
    padding: 20px;
  }

  .callToActionTitle {
    font-size: 1.5rem;
  }
}

.project {
  background-color: #fd6c19;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.projectContent {
  width: 90%;
  background-color: var(--secondary-color);
  border-radius: 10px;
  margin: auto;
}

.project .branding {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
}

.project .choosenType {
  margin: 30px auto;
}

.project .choosenType article {
  display: flex;
  justify-content: flex-start;
  background-color: #f38342;
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin: 10px auto;
  border-radius: 10px;
  width: 60%;
  cursor: pointer;
}

.project .choosenType article img {
  max-width: 100px;
  padding: 20px;
}

.project .choosenType article .choosenRow {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-right: 10px;
}

.project .choosenType article .choosenDescription p:last-child {
  color: #19181c;
  font-size: 16px;
}

.loadingProject {
  max-width: 100px;
  margin: 50px;
}

.inputList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.inputList input {
  width: 60%;
}

.requestError {
  background-color: #f94848;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  width: 60%;
}

.inputList select {
  width: 61%;
}

.formProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #19181c;
  height: 5px;
}

.formProgressState {
  height: 5px;
  background-color: #ffffff;
}

.project .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  color: #fff;
  margin-top: 50px;
}

.project .subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  margin-top: 20px;
  padding: 0 30px;
  margin-bottom: 30px;
}

.projectInputText {
  outline: none;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  width: 700px;
  margin-top: 20px;
  font-size: 24px;
  line-height: 24px;
}

.projectInputTextarea {
  outline: none;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  width: 700px;
  height: 300px;
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  resize: none;
}

.projectInputSelect {
  outline: none;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  width: 720px;
  margin-top: 20px;
  font-size: 24px;
  line-height: 24px;
}

.project .projectButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.project .buttonForm {
  border: 0;
  outline: 0;
  background-color: #19181c;
  border-radius: 5px;
  color: white;
  padding: 10px 30px;
  font-size: 18px;
  line-height: 22px;
  margin: 30px;
  cursor: pointer;
}

.project .buttonForm a{
  color: white !important;
}

.project .buttonRight {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 0;
  outline: 0;
  background-color: #19181c;
  border-radius: 5px;
  padding: 10px 30px;
  color: white;
  font-size: 18px;
  line-height: 22px;
  margin: 30px;
  cursor: pointer;
}

.project .buttonRight:disabled {
  color: #fd6c19;
  background-color: #fd6c19;
  cursor: not-allowed;
}

.project .buttonLeft {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 0;
  outline: 0;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 30px;
  color: white;
  font-size: 16px;
  line-height: 20px;
  margin: 30px;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .project {
    overflow: visible;
  }

  .projectContent {
    width: 98%;
  }
  .project .branding {
    top: 70px;
    max-width: 200px;
  }
  .project .title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .project .subtitle {
    font-size: 18px;
    line-height: 22px;
    padding: 0 5px;
    text-align: center;
  }
  .project .projectButton {
    flex-wrap: wrap;
  }

  .project button {
    margin: 5px;
    font-size: 18px;
    line-height: 22px;
  }

  .projectInputText {
    width: 90%;
    margin-top: 0px;
  }

  .projectInputTextarea {
    width: 90%;
    height: 350px;
    font-size: 14px;
    line-height: 14px;
    resize: none;
  }

  .project .choosenType article {
    width: 100%;
  }

  .project .choosenType article img {
    max-width: 50px;
    padding: 10px;
  }

  .project .choosenType article .choosenRow {
    font-size: 1.2rem;
    padding: 10px 5px;
  }

  .project .choosenType article .choosenDescription p:last-child {
    font-size: 12px;
  }

  .projectInputSelect {
    width: 95%;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .inputList {
    margin-bottom: 10vh;
  }

  .inputList input {
    width: 100%;
    margin: 10px 0;
    font-size: 1rem;
  }

  .requestError {
    width: 100%;
  }

  .inputList select {
    width: 100%;
    margin: 10px 0;
    font-size: 1rem;
  }
}

.legals {
  margin-top: -30vh;
  margin-bottom: 100px;
  padding: 0 50px;
  font-size: 20px;
  text-align: justify;
}

.legalsTitle {
  color: #fd6c19;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 28px;
  margin: 20px 0;
}

.legals ul {
  margin-left: 30px;
  margin-top: 10px;
}

.footer {
  position: relative;
  width: 100%;
  padding: 50px 0;
}

.footerRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footerUsefull {
  max-width: 30%;
  padding: 30px;
}

.footerUsefullTitle {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 20px;
}

.footerUsefullItem {
  margin: 20px 0;
}

.footerUsefullItem a {
  color: #000;
}

.footerLogoRow {
  width: 100%;
  text-align: center;
}

.footerLogo {
  max-width: 300px;
  text-align: center;
  margin-bottom: 20px;
}

.footerUnderline {
  margin: 0 100px;
}

.footerCopyright {
  text-align: center;
  padding: 20px;
  margin-top: -40px;
  margin-left: 20px;
  font-weight: bold;
}

@media (max-width: 1080px) {
  .footer {
    margin-top: -20px;
  }

  .footerRow {
    flex-wrap: wrap-reverse;
    justify-content: center;
    text-align: center;
  }

  .footerUsefull {
    max-width: 100%;
  }
  .footerNewsletterCard {
    max-width: 100%;
  }

  .footerCopyright {
    padding: 0;
    margin-top: -20px;
    margin-left: 0;
  }
}

/*--------------------------------------------------------------
# SCROLLBAR
--------------------------------------------------------------*/

::-webkit-scrollbar {
  width: 10px;
  padding: 5px !important;
}

::-webkit-scrollbar-track {
  background: #18191c;
}

::-webkit-scrollbar-thumb {
  background-color: #fd6c19;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f38342;
}
